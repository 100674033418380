import PropTypes from "prop-types";
import React from "react";

import Loading from "./Loading.js";
/**
 * React Suspense defaults
 * For to Avoid Repetition
 */
const Suspense = props => (
  <React.Suspense fallback={<Loading {...props.loadingProps} />}>
    {props.children}
  </React.Suspense>
);

Suspense.propTypes = {
  loadingProps: PropTypes.object,
  children: PropTypes.node.isRequired,
};

Suspense.defaultProps = {loadingProps: {delay: 0}};

Suspense.displayName = "Suspense";

export default Suspense;
