import React, {useCallback} from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import * as authActions from "app/auth/store/actions/index.js";

import {
  Divider, List, ListItem,
  ListItemIcon, ListItemText, Toolbar,
} from "@mui/material";
import {Dashboard, Business, Gavel, Logout, People} from "@mui/icons-material";

// eslint-disable-next-line max-lines-per-function
const DrawerContent = () => {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const handleLogout = useCallback(
    () => dispatch(authActions.logoutUser()),
    [dispatch],
  );

  return (
    <div>
      <Toolbar />
      <Divider />
      <List>
        <ListItem button component={Link} to="/dashboard">
          <ListItemIcon>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary={t("Dashboard")} />
        </ListItem>
        <ListItem button component={Link} to="/company">
          <ListItemIcon>
            <Business />
          </ListItemIcon>
          <ListItemText primary={t("Company")} />
        </ListItem>
        <ListItem button component={Link} to="/license">
          <ListItemIcon>
            <Gavel />
          </ListItemIcon>
          <ListItemText primary={t("License")} />
        </ListItem>
        <ListItem button component={Link} to="/user">
          <ListItemIcon>
            <People />
          </ListItemIcon>
          <ListItemText primary={t("User")} />
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem
          button
          component={Link}
          to="/login"
          onClick={handleLogout}
        >
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          <ListItemText primary={t("Logout")} />
        </ListItem>
      </List>
    </div>
  );
};

DrawerContent.displayName = "DrawerContent";

export default DrawerContent;
