import {createTheme} from "@mui/material/styles/index.js";

const theme = createTheme({
  palette: {
    text: {
      primary: "#454544",
      disabled: "#f0f0f0",
    },
    primary: {
      light: "#47538b",
      main: "#273577",
      dark: "#23306f",
      contrastText: "#f0f0f0",
    },
    secondary: {
      light: "#26ade7",
      main: "#009fe3",
      dark: "#0097e0",
      contrastText: "#f0f0f0",
    },
    error: {main: "#d33215"},
    success: {main: "#8ab832"},
    warning: {main: "#f3921f"},
  },
});

export default theme;
