import companyConfig from "./company/company.config.js";
import errorsConfig from "./errors/errors.config.js";
import licenseConfig from "./license/license.config.js";
import loginConfig from "./login/login.config.js";
import dashboardConfig from "./dashboard/dashboard.config.js";
import userConfig from "./manage_user/manage_user.config.js";

const mainConfigs = [
  dashboardConfig,
  companyConfig,
  errorsConfig,
  licenseConfig,
  loginConfig,
  userConfig,
];

export default mainConfigs;
