import React from "react";
// import i18next from "i18next";

import {authRoles} from "app/auth/index.js";
// import en from "./i18n/en.js";

// i18next.addResourceBundle("en", "home", en);

const config = {
  settings: {layout: {appBar: true, sideBar: true, false: true}},
  auth: authRoles.user,
  routes: [
    {
      title: "Users",
      path: "/user",
      exact: true,
      component: React.lazy(() => import("./manage_users.js")),
    },
    {
      title: "User",
      path: "/user/:id",
      exact: true,
      component: React.lazy(() => import("./manage_user.js")),
    },
  ],
};

export default config;
