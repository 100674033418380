import React, {useCallback, useContext, useMemo, useState} from "react";
import {useDispatch} from "react-redux";

import {renderRoutes, matchRoutes} from "react-router-config";

import {ToastContainer} from "react-toastify";
import {useLocation} from "react-router-dom";

import {
  Box,
  Drawer,
  CssBaseline,
  AppBar, Toolbar, IconButton, Typography, Tooltip,
} from "@mui/material";

import {LogoutOutlined, Menu as MenuIcon} from "@mui/icons-material";

import * as authActions from "app/auth/store/actions/index.js";

import AppContext from "../AppContext.js";

import Suspense from "./components/Suspense.js";
import DrawerContent from "./components/DrawerContent.js";
import Footer from "./components/Footer.js";

const drawerWidth = 240;

// eslint-disable-next-line max-lines-per-function
const Layout = () => {
  const appContext = useContext(AppContext);

  const [mobileOpen, setMobileOpen] = useState(false);
  const dispatch = useDispatch();

  const {routes} = appContext;
  const _location = useLocation();
  const {pathname} = _location;
  const matched = matchRoutes(routes, pathname)[0];

  const container = useMemo(() => window === undefined ? undefined : window.document.body, []);

  const handleDrawerToggle = useCallback(() => {
    setMobileOpen(!mobileOpen);
  }, [mobileOpen]);

  const layoutSettings = matched?.route?.settings?.layout || {};

  const title = matched?.route?.title || "";

  const handleLogout = useCallback(
    () => dispatch(authActions.logoutUser()),
    [dispatch],
  );

  return (
    <Box sx={{display: "flex"}}>
      <CssBaseline />
      {
        layoutSettings.appBar && (
          <AppBar
            position="fixed"
            sx={{
              width: {sm: `calc(100% - ${drawerWidth}px)`},
              ml: {sm: `${drawerWidth}px`},
            }}
          >
            <Toolbar>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{mr: 2, display: {sm: "none"}}}
              >
                <MenuIcon />
              </IconButton>
              <Typography variant="h6" noWrap component="div">
                {title}
              </Typography>
              <Tooltip title="Logout">
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="end"
                  sx={{ml: "auto"}}
                  onClick={handleLogout}
                >
                  <LogoutOutlined />
                </IconButton>
              </Tooltip>
            </Toolbar>
          </AppBar>
        )
      }
      {
        layoutSettings.sideBar && (
          <Box
            component="nav"
            sx={{width: {sm: drawerWidth}, flexShrink: {sm: 0}}}
            aria-label="mailbox folders"
          >
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{keepMounted: true}}
              sx={{
                "display": {xs: "block", sm: "none"},
                "& .MuiDrawer-paper": {boxSizing: "border-box", width: drawerWidth},
              }}
            >
              <DrawerContent />
            </Drawer>
            <Drawer
              open
              anchor="left"
              variant="permanent"
              sx={{
                "display": {xs: "none", sm: "block"},
                "& .MuiDrawer-paper": {boxSizing: "border-box", width: drawerWidth},
              }}
            >
              <DrawerContent />
            </Drawer>
          </Box>
        )
      }
      <Box
        component="main"
        sx={{
          display: "flex",
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "space-between",
          minHeight: "90vh",
          mt: 8,
          pt: 3,
          px: 3,
        }}
      >
        {/* {layoutSettings.appBar && <Toolbar />} */}
        <Suspense>
          {renderRoutes(routes)}
        </Suspense>
        {layoutSettings.footer && <Footer />}
      </Box>
      <ToastContainer />
    </Box>
  );
};

Layout.displayName = "Layout";

export default React.memo(Layout);
