const domain = "https://dashboard.keeex.me";
const version = "v1";

const baseURL = `${domain}/api/${version}`;
const accessTokenKey = "jat_<dashboard.keeex.me>";

const apiConfig = {
  baseURL,
  accessTokenKey,
};

export default apiConfig;
