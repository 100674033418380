import React from "react";
import {Redirect} from "react-router-dom";
import i18next from "i18next";

import {authRoles} from "app/auth/index.js";
import en from "./i18n/en.js";

i18next.addResourceBundle("en", "dashboard", en);

const RedirectToDashboard = () => <Redirect to="/company" />;

RedirectToDashboard.displayName = "RedirectToCompany";

const dashboardConfig = {
  settings: {layout: {appBar: true, sideBar: true, footer: true}},
  auth: authRoles.user,
  routes: [
    {
      path: "/",
      exact: true,
      component: RedirectToDashboard,
    },
    {
      title: "Dashboard",
      path: "/dashboard",
      exact: true,
      component: React.lazy(() => import("./DashboardPage.js")),
    },
  ],
};

export default dashboardConfig;
