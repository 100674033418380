import React from "react";
import {Router} from "react-router-dom";
import Provider from "react-redux/es/components/Provider.js";
import {PersistGate} from "redux-persist/lib/integration/react.js";
import {ThemeProvider} from "@mui/material/styles/index.js";

import _history from "@history";

import Authorization from "app/auth/authorization.js";
import Layout from "app/layout/Layout.js";

import AppContext from "./AppContext.js";
import routes from "./configs/routes.config.js";
import {Auth} from "./auth/index.js";
import reduxStore from "./store/index.js";

import theme from "./theme.js";

import "./app.css";
import "./i18n/i18n.config.js";

const App = () => (
  <ThemeProvider theme={theme}>
    <AppContext.Provider value={{routes}}>
      <Provider store={reduxStore.store}>
        <PersistGate loading={null} persistor={reduxStore.persistor}>
          <Auth>
            <Router history={_history}>
              <Authorization>
                <Layout />
              </Authorization>
            </Router>
          </Auth>
        </PersistGate>
      </Provider>
    </AppContext.Provider>
  </ThemeProvider>
);

App.displayName = "App";

export default App;
