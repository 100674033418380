import jwtService from "app/services/jwt.js";
import * as UserActions from "./user.action.js";

export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";

export const submitLogin = ({email, password}) => dispatch => jwtService
  .signInWithEmailAndPassword(email, password)
  .then(user => {
    dispatch(UserActions.setUserData(user));

    dispatch({type: LOGIN_SUCCESS});

    return user;
  })
  .catch(error => {
    dispatch({
      type: LOGIN_ERROR,
      payload: error.response ? error.response.data : error,
    });

    throw error;
  });
