import React from "react";
import i18next from "i18next";

import {authRoles} from "app/auth/index.js";
import en from "./i18n/en.js";

i18next.addResourceBundle("en", "login", en);

const loginConfig = {
  settings: {layout: {footer: true}},
  auth: authRoles.onlyGuest,
  routes: [
    {
      path: "/login",
      exact: true,
      component: React.lazy(() => import("./LoginPage.js")),
    },
  ],
};

export default loginConfig;
