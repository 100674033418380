import React from "react";
// import i18next from "i18next";

import {authRoles} from "app/auth/index.js";
// import en from "./i18n/en.js";

// i18next.addResourceBundle("en", "home", en);

const config = {
  settings: {layout: {appBar: true, sideBar: true, footer: false}},
  auth: authRoles.user,
  routes: [
    {
      title: "Licenses",
      path: "/license",
      exact: true,
      component: React.lazy(() => import("./licenses.js")),
    },
    {
      title: "License",
      path: "/license/:id",
      exact: true,
      component: React.lazy(() => import("./license.js")),
    },
  ],
};

export default config;
