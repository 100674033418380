import React from "react";

import {Box, Typography} from "@mui/material";

const Footer = () => (
  <Box sx={{mt: 2, py: 2}}>
    <Typography align="center">
      Copyright &copy; {new Date().getFullYear()} KeeeX SAS
    </Typography>
  </Box>
);

Footer.displayName = "Footer";

export default Footer;
