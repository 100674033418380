import React from "react";
import i18next from "i18next";

import {authRoles} from "app/auth/index.js";
import en from "./i18n/en.js";

i18next.addResourceBundle("en", "home", en);

const homeConfig = {
  settings: {layout: {appBar: true, sideBar: true}},
  auth: authRoles.user,
  routes: [
    {
      title: "",
      path: "/errors/404",
      exact: true,
      component: React.lazy(() => import("./404.js")),
    },
  ],
};

export default homeConfig;
