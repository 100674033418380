import React from "react";
import {Redirect} from "react-router-dom";
import mainConfig from "app/main/main.config";
import Utils from "../helpers/utils.js";

const routeConfigs = [
  ...mainConfig,
];

const routes = [
  ...Utils.generateRoutesFromConfigs(routeConfigs, null),
  // eslint-disable-next-line react/display-name
  {component: () => <Redirect to="/errors/404" />},
];

export default routes;
