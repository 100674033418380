import React from "react";
import i18next from "i18next";

import {authRoles} from "app/auth/index.js";
import en from "./i18n/en.js";

i18next.addResourceBundle("en", "home", en);

const companyConfig = {
  settings: {layout: {appBar: true, sideBar: true, footer: false}},
  auth: authRoles.user,
  routes: [
    {
      title: "Companies",
      path: "/company",
      exact: true,
      component: React.lazy(() => import("./companies.js")),
    },
    {
      title: "Company",
      path: "/company/:id",
      exact: true,
      component: React.lazy(() => import("./company.js")),
    },
  ],
};

export default companyConfig;
